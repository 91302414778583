<script setup lang="ts">
import type { DefaultLayoutQuery } from '#gql';

withDefaults(defineProps<{
  mainNavigationItems: DefaultLayoutQuery['mainNavigation']['items'];
}>(), {  })

const nuxtApp = useNuxtApp();

const {
  getSelectedSecondLevelIndex,
  getSelectedThirdLevelIndex,
  isSecondLevelOpen,
  isThirdLevelOpen,
} = useBurgerNavigation();

const { locale } = useI18n();

const GqlInstance = useGql();

const getClasses = computed(() => [
  { 'first-level-open': !isSecondLevelOpen.value && !isThirdLevelOpen.value },
  { 'second-level-open': isSecondLevelOpen.value },
  { 'third-level-open': isThirdLevelOpen.value },
]);

const firstLevel = computed(() => __props.mainNavigationItems?.[getSelectedSecondLevelIndex.value]);

const {
  data,
  status,
} = await useAsyncData(
  `navigation-item-${firstLevel.value?._uid}-${locale.value}`,
  async () => {
    if (firstLevel.value) {
      return GqlInstance('NavigationItem', {
        id: firstLevel.value._uid || '',
        localeCode: locale.value,
      });
    }
    return null;
  },
  {
    getCachedData: (key) => (nuxtApp.static.data[key] || nuxtApp.payload.data[key]),
    watch: [ firstLevel ],
  },
);

const isLoading = computed(() => firstLevel.value && status.value === 'pending');

const secondLevel = computed(() => data?.value?.navigationItem.groups?.[getSelectedThirdLevelIndex.value]);
</script>

<template>
  <div class="burger-menu-content relative z-10 grid">
    <LazyBurgerMenuFirstLevel
      v-if="!isLoading && mainNavigationItems"
      :class="getClasses"
      :items="mainNavigationItems"
    />
    <LazyBurgerMenuSecondLevel
      v-if="!isLoading"
      :class="getClasses"
      :items="data?.navigationItem.groups"
      :parent="firstLevel?.title"
    />
    <LazyBurgerMenuThirdLevel
      v-if="!isLoading"
      :class="getClasses"
      :items="secondLevel?.columns"
      :parent="secondLevel?.title"
    />
  </div>
  <UiLoadingSpinner
    v-if="isLoading"
    is-small
    class="flex self-center justify-self-center"
  />
</template>

<style lang="scss">
@mixin burger-menu-slide($percentage) {
  transition: transform .3s ease-in-out;
  transform: translateX($percentage);
}

.burger-menu-content {
  grid-template-columns: repeat(3, minmax(100%, 1fr));
}

.first-level-open {
  @include burger-menu-slide(-100%);
}

.second-level-open {
  @include burger-menu-slide(-200%);
}

.third-level-open {
  @include burger-menu-slide(-300%);
}
</style>
